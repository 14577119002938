import { createReducer, on, Action } from '@ngrx/store';

import { supportedLanguagesActionGroup } from './supported-languages.actions';
import { initialState, State } from './supported-languages.state';

const reducerFunction = createReducer(
  initialState,
  on(supportedLanguagesActionGroup.loadRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(supportedLanguagesActionGroup.loadSuccess, (state, { locals }) => ({
    ...state,
    locals,
  })),
  on(supportedLanguagesActionGroup.loadFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(supportedLanguagesActionGroup.loadWebsitesLanguagesRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(
    supportedLanguagesActionGroup.loadWebsitesLanguagesSuccess,
    (state, { websitesLanguages }) => ({
      ...state,
      websitesLanguages,
    }),
  ),
  on(
    supportedLanguagesActionGroup.loadWebsitesLanguagesFailure,
    (state, { error }) => ({
      ...state,
      error,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return reducerFunction(state, action);
}
