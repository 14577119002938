import { Accommodation } from "./accommodation.model";

export interface PropertyStatistics {
  property: Accommodation;
  website_quality: number;
  revenue: number;
  reservations_count: number;
  conversions_count: number;
  points_of_interest_count: number;
  tags_count: number;
  events_count: number;
  website_url: boolean;
  booking_engine: boolean;
  parity_rate: boolean;
}
