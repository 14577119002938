export interface GetQuotationRequest {
  connections?: {
    externalConnectionCode: string;
    externalAccommodationCodes: string[];
  }[];
  rechargeAmount?: number;
  voucherCode?: string;
}

export interface MakePaymentRequest {
  paymentMethodId: string;
  token: string;
}
