import { FormControl } from '@angular/forms';
import { ControlsOf } from './controls-of.model';

export interface SemParam {
  semParamId: string;
  organizationId?: string;
  key: number;
  value: string;
}

export interface SemParamForm
  extends ControlsOf<Pick<SemParam, 'key' | 'semParamId' | 'organizationId'>> {
  value: FormControl<number>;
}
