export const FEATURE_KEY = 'balance';

export interface State {
  error: unknown;
  loading: boolean;
  balance: number;
}

export const initialState: State = {
  error: null,
  loading: false,
  balance: 0,
};
