import { QuotationPricelist } from './pricelists.model';

export interface Quotation {
  quotationId: string;
  activePriceList: QuotationPricelist;
  defaultPriceList: QuotationPricelist;
  voucher?: {
    code: string;
    id: string;
  };
  details: QuotationDetail[];
  totalGrossAmount: number;
  expirationDate: string;
  token: string;
}

interface QuotationDetail {
  type: number;
  netAmount: number;
  vatPercentage: number;
  externalConnectionCode: string;
  externalAccommodationCode: string;
  roomCount: number;
}

export interface PaymentMethod {
  id: string;
  label: string;
}

export interface PaymentInfo {
  bankTransferDetails: string;
  onlinePaymentProviderRedirectLink?: string;
  paymentId: string;
}
