import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SearchEnginesApiService } from '@booking-booster-client/api';
import * as fromActions from './search-engines.actions';
import * as fromState from './search-engines.state';
import { Store } from '@ngrx/store';

@Injectable()
export class SearchEnginesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromState.State>,
    private searchEnginesApiService: SearchEnginesApiService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() => {
        return this.searchEnginesApiService.loadSearchEngines().pipe(
          map(({ searchEngines }) => {
            return fromActions.loadSuccess({
              searchEngines,
            });
          }),
          catchError((error) => {
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );
}
