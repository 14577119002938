import { User, Nullable, UserPermission } from '@booking-booster-client/models';

export const USER_FEATURE_KEY = 'userSession';

export interface UserSessionsState {
  user: Nullable<User>;
  permissions: UserPermission[];
  selectedOrganizationId?: string;
  error: unknown;
}

export const initialState: UserSessionsState = {
  user: undefined,
  permissions: [],
  selectedOrganizationId: undefined,
  error: null,
};
