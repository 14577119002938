import { SearchParams } from "@booking-booster-client/helpers";

export interface LoadPoisRequest extends SearchParams {
  latitude: number;
  longitude: number;
  categories: string[];
  radius: number
  search?: string;
  page?: number;
}
