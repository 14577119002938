import { BBRoute } from '@booking-booster-client/models';
import { PermissionLabel } from 'libs/models/src/lib/models/permission.model';

export function filterRoutes(
  routes: BBRoute[],
  permissions: PermissionLabel[],
): BBRoute[] {
  return routes.reduce((filteredRoutes: BBRoute[], route) => {
    if (route.requiredPermissions) {
      if (
        !route.requiredPermissions.every((requiredPermission) =>
          permissions.includes(requiredPermission),
        )
      ) {
        return filteredRoutes;
      }
    }

    if (route.children?.length) {
      const newRoute: BBRoute = {
        ...route,
        children: filterRoutes(route.children, permissions),
      };

      if (newRoute.children?.length) {
        return [...filteredRoutes, newRoute];
      }

      return filteredRoutes;
    }

    return [...filteredRoutes, route];
  }, []);
}
