import { Url } from '../models/url.model';
import { Poi } from './poi.model';

export interface Accommodation {
  name: string;
  accommodationId: string;
  address: string;
  city: string;
  country: string;
  county: string;
  email: string;
  facebook: string;
  facilityIds: string[];
  id: number;
  instagram: string;
  logoUrl: string;
  latitude: number;
  longitude: number;
  phone: string;
  roomCount: number;
  stars: number;
  state: string;
  threads: string;
  tiktok: string;
  type: string;
  websites: Url[];
  x: string;
  zipCode: string;
  activityIds: string[];
  externalPoiCodes: string[];
  //Probably the following properties do not exist anymore, TODO: check that
  activityCount?: number;
  currency_symbol?: string;
  tags_ids?: number[];
  pois?: Poi[];
  services_ids?: string[];
  messages?: string[];
  website_quality?: number;
  revenue?: number;
  reservations_count?: number;
  conversions_count?: number;
  points_of_interest_count?: number;
  tags_count?: number;
  website_url?: boolean;
  booking_engine?: boolean;
  parity_rate?: boolean;
}

export interface AccommodationInList {
  accommodationId: string;
  activityCount: number;
  address: string;
  city: string;
  country: string;
  county: string;
  email: string;
  externalAccommodationCode: string;
  externalConnectionCode: string;
  latitude: number;
  logoUrl: string;
  longitude: number;
  name: string;
  organizationId: string;
  phone: string;
  stars: number;
  state: string;
  tagCount: number;
  type: string;
  websiteCount: number;
  zipCode: string;
  poiCount: number;
  revenue: number;
  bookings: number;
  conversions: number;
}

export interface AccommodationFacility {
  code: string;
  name: string;
}
