import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './organization.state';
import { organizationActionsGroup } from './organization.actions';
import * as fromState from './organization.state';

const organizationsReducer = createReducer(
  initialState,
  on(organizationActionsGroup.createRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(organizationActionsGroup.createSuccess, (state, { organization }) =>
    fromState.featureAdapter.addOne(organization, {
      ...state,
      loading: false,
    }),
  ),
  on(organizationActionsGroup.createFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(organizationActionsGroup.loadRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(organizationActionsGroup.loadSuccess, (state, { dataOrganizations }) =>
    fromState.featureAdapter.setAll(dataOrganizations.organizations, {
      ...state,
      loading: false,
    }),
  ),
  on(organizationActionsGroup.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return organizationsReducer(state, action);
}
