import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LanguagesApiService } from '@booking-booster-client/api';
import { supportedLanguagesActionGroup } from './supported-languages.actions';

@Injectable()
export class SupportedLanguagesEffects {
  constructor(
    private actions$: Actions,
    private supportedLanguageService: LanguagesApiService,
  ) {}

  loadLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportedLanguagesActionGroup.loadRequest),
      switchMap(() =>
        this.supportedLanguageService.loadSupportedLanguages().pipe(
          map((data) => {
            return supportedLanguagesActionGroup.loadSuccess({
              locals: data,
            });
          }),
          catchError((error) => {
            return of(supportedLanguagesActionGroup.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadWebsitesLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportedLanguagesActionGroup.loadWebsitesLanguagesRequest),
      switchMap(() =>
        this.supportedLanguageService.loadWebsitesLanguages().pipe(
          map((data) => {
            return supportedLanguagesActionGroup.loadWebsitesLanguagesSuccess({
              websitesLanguages: Object.keys(data.accommodationsWebsitesByLocales),
            });
          }),
          catchError((error) => {
            return of(
              supportedLanguagesActionGroup.loadWebsitesLanguagesFailure(error),
            );
          }),
        ),
      ),
    ),
  );
}
