import { createReducer, on, Action } from '@ngrx/store';

import { usersActionGroup as UserActions } from './user-session.actions';
import { initialState, UserSessionsState } from './user-session.state';

const reducerFunction = createReducer(
  initialState,
  on(UserActions.loadRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(UserActions.loadSuccess, (state, { user }) => {
    return {
      ...state,
      user,
    };
  }),
  on(UserActions.loadFailure, (state, { error }) => ({ ...state, error })),

  on(UserActions.loadPermissionsRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(UserActions.loadPermissionsSuccess, (state, { permissions }) => {
    return {
      ...state,
      permissions,
    };
  }),
  on(UserActions.loadPermissionsFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(UserActions.setSelectedOrganizationRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(
    UserActions.setSelectedOrganizationSuccess,
    (state, { organizationId }) => {
      return {
        ...state,
        selectedOrganizationId: organizationId,
      };
    },
  ),
  on(UserActions.setSelectedOrganizationFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UserActions.resetState, () => initialState),
);

export function reducer(state: UserSessionsState | undefined, action: Action) {
  return reducerFunction(state, action);
}
