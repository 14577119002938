import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Facility,
  LoadServicesRequest,
  Paginated,
} from '@booking-booster-client/models';
import { generateSearchQuery } from '@booking-booster-client/helpers';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesService {
  constructor(private httpClient: HttpClient) {}

  get(request?: LoadServicesRequest) {
    return this.httpClient.get<Paginated<Facility>>(
      `api/facilities${generateSearchQuery(request)}`,
    );
  }
}
