import { isArray } from 'lodash-es';
import { dateToServerFormat } from './date-to-server-format';

export type SearchParams = Record<string, SearchParamValue>;

export type SearchParamValue =
  | string
  | string[]
  | number
  | number[]
  | Date
  | undefined
  | null;

export function generateSearchQuery(
  searchParams: SearchParams | undefined | null,
): string {
  if (
    !searchParams ||
    Object.values(searchParams).filter(Boolean)?.length === 0
  ) {
    return '';
  }

  const keyValueCallback = (key: string, item: SearchParamValue) => {
    return `${key}=${dateToServerFormat(item)}`;
  };

  return `?${Object.entries(searchParams)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      if (isArray(value)) {
        return value.map((item) => keyValueCallback(key, item)).join('&');
      }
      return keyValueCallback(key, value);
    })
    .join('&')}`;
}
