import { FormControl } from '@angular/forms';
import { Url } from './url.model';

export interface OnboardWebsiteStepUrlForm {
  [propertyID: string]: FormControl<Url[]>;
}

export interface UrlForm {
  locale: FormControl<string>;
  url: FormControl<string>;
}
