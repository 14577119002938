/* eslint-disable @typescript-eslint/no-unused-vars */
import { Accommodation } from '@booking-booster-client/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Nullable } from 'subsink/dist/subsink';
import {
  USER_FEATURE_KEY,
  State,
  featureAdapter,
} from './accommodations.state';

export const selectState = createFeatureSelector<State>(USER_FEATURE_KEY);

export const { selectAll } = featureAdapter.getSelectors(selectState);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);

export const selectPagination = createSelector(
  selectState,
  (state: State) => state.pagination,
);

export const selectFilteredAccommodations = createSelector(
  selectAll,
  (accommodations) => accommodations,
);

export const selectSelectedAccommodations = createSelector(
  selectState,
  (state: State) => state.selectedAccommodations,
);

export const selectAccommodationsIds = createSelector(
  selectState,
  (state: State) => state.accommodationIds,
);

export const selectSelectedAccommodationIds = createSelector(
  selectSelectedAccommodations,
  (selectedProperties: Nullable<Record<string, Partial<Accommodation>>>) =>
    Object.keys(selectedProperties || {}).map(String),
);
