import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { first, Observable, switchMap } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { select, Store } from '@ngrx/store';
import {
  environment,
  UserSessionsState,
  UserSessionStoreSelectors,
} from '@booking-booster-client/root-store';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private oAuthService: OAuthService,
    private store: Store<UserSessionsState>,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes('http://') || request.url.includes('https://')) {
      return next.handle(request);
    }

    if (request.url.includes('api/')) {
      request = request.clone({
        url: environment.host + request.url,
      });

      let headers = request.headers;

      if (this.oAuthService.hasValidIdToken()) {
        headers = headers.set(
          'Authorization',
          `Bearer ${this.oAuthService.getIdToken()}`,
        );
        return this.store.pipe(
          select(UserSessionStoreSelectors.selectSelectedOrganizationId),
          first(),
          switchMap((organization) => {
            if (organization) {
              headers = headers.set('zbb-organization-id', organization);
            }

            request = request.clone({ headers });
            return next.handle(request);
          }),
        );
      }
    }

    return next.handle(request);
  }
}
