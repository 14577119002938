import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {
  AccommodationInList,
  ApiResponsePagination,
  LoadUserAccommodationsRequest,
  Nullable,
} from '@booking-booster-client/models';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { SubSink } from 'subsink';
import * as PropertiesSelectors from './accommodations.selectors';
import * as PropertiesActions from './accommodations.actions';
import { UserSessionStoreSelectors } from '../user-session';

export class AccommodationsSource extends DataSource<AccommodationInList> {
  private pagination: Nullable<ApiResponsePagination> = null;

  private cachedData: AccommodationInList[] = [];

  private fetchedPages = new Set<number>();

  private complete$ = new Subject<void>();

  private subs = new SubSink();

  organizationId?: string;

  constructor(
    private store: Store,
    private filters?: Omit<LoadUserAccommodationsRequest, 'page'>,
  ) {
    super();

    this.subs.add(
      this.selectedOrganizationId$.subscribe((organizationId) => {
        if (organizationId) {
          this.organizationId = organizationId;
        }
      }),
    );
  }

  selectedOrganizationId$ = this.store.pipe(
    select(UserSessionStoreSelectors.selectSelectedOrganizationId),
  );

  completed(): Observable<void> {
    return this.complete$.asObservable();
  }

  connect(
    collectionViewer: CollectionViewer,
  ): Observable<AccommodationInList[]> {
    this.setup(collectionViewer);

    return this.store.pipe(
      select(PropertiesSelectors.selectAll),
      tap((properties) => {
        this.cachedData = properties;
      }),
    );
  }

  disconnect() {
    this.subs.unsubscribe();
  }

  get length(): number {
    return this.cachedData?.length;
  }

  private setup(collectionViewer: CollectionViewer): void {
    this.fetchPage(1);

    this.subs.add(
      collectionViewer.viewChange
        .pipe(takeUntil(this.complete$))
        .subscribe((range) => {
          if (this.cachedData.length >= (this.pagination?.total || 0)) {
            this.complete$.next();
            this.complete$.complete();
          } else {
            const endPage = this.getPageForIndex(range.end);
            this.fetchPage(endPage + 1);
          }
        }),
    );

    this.subs.add(
      this.store
        .pipe(select(PropertiesSelectors.selectPagination))
        .subscribe((pagination) => {
          this.pagination = pagination;
        }),
    );
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / (this.pagination?.per_page || 25));
  }

  private fetchPage(page: number) {
    if (this.fetchedPages.has(page)) {
      return;
    }

    this.fetchedPages.add(page);

    this.store.dispatch(
      PropertiesActions.loadRequest({
        request: { ...this.filters, page },
        disableLoading: page > 1,
      }),
    );
  }
}
