import { createAction, props } from '@ngrx/store';
import { SearchEngine } from '@booking-booster-client/models';

export const loadRequest = createAction('[SearchEngines] Load Request');
export const loadSuccess = createAction(
  '[SearchEngines] Load Success',
  props<{
    searchEngines: SearchEngine[];
  }>(),
);
export const loadFailure = createAction(
  '[SearchEngines] Load Failure',
  props<{ error: unknown }>(),
);

export const reset = createAction('[SearchEngines] Reset');
