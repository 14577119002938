import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  LoadUsersRequest,
  Paginated,
  UserInList,
} from '@booking-booster-client/models';
import { generateSearchQuery } from '@booking-booster-client/helpers';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private http: HttpClient) {}

  load(request: LoadUsersRequest) {
    return this.http.get<Paginated<UserInList>>(
      `api/users${generateSearchQuery(request)}`,
    );
  }

  delete(id: string) {
    return this.http.delete<boolean>(`api/users/${id}`);
  }
}
