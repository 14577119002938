import { PermissionLabel } from './permission.model';

export interface User {
  email: string;
  name: string;
  pictureUrl: string;
  surname: string;
  userId: string;
  isSystem: boolean;
  permissions: PermissionLabel[];
}

export interface UserInList extends Omit<User, 'pictureUrl'> {}
