import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DataOrganization, Organization } from '@booking-booster-client/models';

export const organizationActionsGroup = createActionGroup({
  source: 'Organization',
  events: {
    'Create Request': props<{
      name: string;
    }>(),
    'Create Success': props<{
      organization: Organization;
    }>(),
    'Create Failure': props<{
      error: unknown;
    }>(),

    'Load Request': emptyProps,

    'Load Success': props<{
      dataOrganizations: DataOrganization;
    }>(),

    'Load Failure': props<{
      error: unknown;
    }>(),
  },
});
