import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { EffectsModule } from '@ngrx/effects';
import {
  provideStoreDevtools,
  StoreDevtoolsModule,
} from '@ngrx/store-devtools';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppLocaleModule } from './app-locale.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppHttpInterceptor } from './interceptors/app-http-interceptor.interceptor';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthGuard } from './guards';
import { TruncateCharPipe } from './pipes/truncate-char.pipe';
import { UpperFirstPipe } from '@z-trippete/angular-pipes';
import { SigninGuard } from './guards/signin.guard';
import { CurrencyPipe } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {
  environment,
  RootStoreModule,
} from '@booking-booster-client/root-store';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    ToastModule,
    BrowserModule,
    AppLocaleModule,
    RootStoreModule,
    AppRoutingModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    TranslateModule,
    TruncateCharPipe,
    OAuthModule.forRoot(),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    AuthGuard,
    SigninGuard,
    CurrencyPipe,
    DialogService,
    MessageService,
    UpperFirstPipe,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideStoreDevtools({
      name: 'booking-booster',
      maxAge: 30,
      trace: true,
      connectInZone: true,
    }),
  ],
})
export class AppModule {}
