import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrganizationStoreModule } from './organization-state/organization-store.module';
import { SupportedLanguagesStoreModule } from './supported-languages/supported-languages.module';
import { ChannelStoreModule } from './channel-state/channel-store.module';
import { SearchEnginesStateModule } from './search-engines/search-engines-state.module';
import { AccommodationsStateModule } from './accommodations/accommodations-state.module';
import { PropertiesTagsStateModule } from './properties-tags/properties-tags-state.module';
import { UserSessionStateModule } from './user-session/user-session-state.module';
import { environment } from '../environments/environment';
import { BalanceStoreModule } from './balance';

@NgModule({
  imports: [
    CommonModule,
    BalanceStoreModule,
    ChannelStoreModule,
    UserSessionStateModule,
    OrganizationStoreModule,
    SearchEnginesStateModule,
    AccommodationsStateModule,
    PropertiesTagsStateModule,
    SupportedLanguagesStoreModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot(),
  ],
})
export class RootStoreModule {}
