import { ResolveFn } from '@angular/router';
import { filter, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Nullable } from '@booking-booster-client/models';
import { Store, select } from '@ngrx/store';
import { OrganizationSelectComponent } from '../modules/signin/organization-select/organization-select.component';
import { TranslateService } from '@ngx-translate/core';
import { UpperFirstPipe } from '@z-trippete/angular-pipes';
import { OrganizationApiService } from '@booking-booster-client/api';
import {
  organizationActionsGroup,
  OrganizationState,
  UserSessionStoreActions,
  UserSessionStoreSelectors,
} from '@booking-booster-client/root-store';
import { LocalStorageService } from '@booking-booster-client/services';
import { ORGANIZATION_SELECTED } from '@booking-booster-client/helpers';

export const selectedOrganizationResolver: ResolveFn<Nullable<string>> = () => {
  const localStorageService = inject(LocalStorageService);
  const dialogService = inject(DialogService);
  const store = inject(Store<OrganizationState>);
  const traslateService = inject(TranslateService);
  const upperFirst = inject(UpperFirstPipe);
  const organizationsApiService = inject(OrganizationApiService);

  store.dispatch(organizationActionsGroup.loadRequest());

  const organizationId$ = store.pipe(
    select(UserSessionStoreSelectors.selectSelectedOrganizationId),
    filter((organization) => !!organization),
  );

  const organizationId: string = localStorageService.getItem(
    ORGANIZATION_SELECTED,
  );

  return organizationsApiService.getOrganizations().pipe(
    switchMap((dataOrganizations) => {
      if (
        dataOrganizations.organizations.some(
          (organization) => organization.organizationId === organizationId,
        )
      ) {
        store.dispatch(
          UserSessionStoreActions.setSelectedOrganizationRequest({
            organizationId,
          }),
        );

        return of(organizationId);
      }

      return dialogService
        .open(OrganizationSelectComponent, {
          header: upperFirst.transform(
            traslateService.instant('select_organization'),
          ),
          width: '600px',
          height: 'auto',
          closable: false,
        })
        .onChildComponentLoaded.pipe(switchMap((c) => c.closedChange$))
        .pipe(switchMap(() => organizationId$));
    }),
  );
};
