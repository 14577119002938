import { AccommodationsTag } from '@booking-booster-client/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const FEATURE_KEY = 'accommodationsTags';

export const featureAdapter = createEntityAdapter<AccommodationsTag>({
  selectId: (model) => model.tagId,
});

export interface AccommodationTagsState extends EntityState<AccommodationsTag> {
  loading: boolean;
  error: unknown;
}

export const initialState: AccommodationTagsState = featureAdapter.getInitialState({
  loading: false,
  error: null,
});
