import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Paginated,
  SearchEngine,
  SearchEngineConnection,
  SearchEngineConnectionBudget,
  SearchEngineConnectionBudgetCreateRequest,
  SearchEngineConnectionsLoadRequest,
  SearchEngineConnectiosBudgetsLoadRequest,
} from '@booking-booster-client/models';
import { generateSearchQuery } from '@booking-booster-client/helpers';

@Injectable({
  providedIn: 'root',
})
export class SearchEnginesApiService {
  constructor(private http: HttpClient) {}

  load(request: SearchEngineConnectionsLoadRequest) {
    return this.http.get<Paginated<SearchEngineConnection>>(
      `api/searchEngineConnections/${generateSearchQuery(request)}`,
    );
  }

  loadDetail(searchEngineConnectionId: string) {
    return this.http.get<{ searchEngineConnection: SearchEngineConnection }>(
      `api/searchEngineConnections/${searchEngineConnectionId}`,
    );
  }

  loadSearchEngines() {
    return this.http.get<{ searchEngines: SearchEngine[] }>(
      `api/searchEngines`,
    );
  }

  loadSearchEngineConnectionBudgets(
    search: SearchEngineConnectiosBudgetsLoadRequest,
  ) {
    return this.http.get<Paginated<SearchEngineConnectionBudget>>(
      `api/searchEngineBudgets${generateSearchQuery(search)}`,
    );
  }

  createSearchEngineConnectionBudget(
    request: SearchEngineConnectionBudgetCreateRequest,
  ) {
    return this.http.post<SearchEngineConnectionBudget>(
      `api/searchEngineBudgets`,
      request,
    );
  }

  editSearchEngineConnectionBudget(
    request: SearchEngineConnectionBudgetCreateRequest,
    searchEngineConnectionBudgetId: string,
  ) {
    return this.http.put<{ budget: SearchEngineConnectionBudget }>(
      `api/searchEngineBudgets/${searchEngineConnectionBudgetId}`,
      request,
    );
  }
}
