import { PermissionLabel } from './permission.model';

export interface FeatureRouterData {
  permissionsNeeded?: PermissionLabel[];
  redirectsTo?: string[];
}

export interface BBRoute {
  iconClass: string;
  url: string;
  navigateOnClick: boolean;
  title: string;
  requiredPermissions?: PermissionLabel[];
  children?: BBRoute[];
}
