import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, AccommodationTagsState, featureAdapter } from './properties-tags.state';

export const selectState = createFeatureSelector<AccommodationTagsState>(FEATURE_KEY);

export const { selectAll } = featureAdapter.getSelectors(selectState);

export const selectError = createSelector(
  selectState,
  (state: AccommodationTagsState) => state.error
);
