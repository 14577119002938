import { isDate } from 'lodash-es';
import { format } from 'date-fns';

export const DEFAULT_SERVER_FORMAT = 'yyyy-MM-dd';

export function dateToServerFormat(
  date: Date | any,
  dateFormat = DEFAULT_SERVER_FORMAT,
): string {
  if (!isDate(date)) {
    return date;
  }

  return format(date, dateFormat);
}
