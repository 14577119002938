import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  LoadPricelistsRequest,
  Paginated,
  Pricelist,
} from '@booking-booster-client/models';
import { generateSearchQuery, magic } from '@booking-booster-client/helpers';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PricelistsApiService {
  constructor(private httpClient: HttpClient) {}

  load(request: LoadPricelistsRequest) {
    return this.httpClient.get<Paginated<Pricelist>>(
      `api/pricelists${generateSearchQuery(request)}`,
    );
  }

  create(request: Omit<Pricelist, 'id'>) {
    return this.httpClient.post<boolean>(`api/pricelists`, request);
  }
}
