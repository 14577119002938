export interface Url {
  locale: string;
  url: string;
}

export interface WebsitesByLocale {
  [locale: string]: {
    accommodationId: string;
    url: string;
  }[];
}
