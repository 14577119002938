import {
  AccommodationInList,
  ApiResponsePagination,
  Nullable,
} from '@booking-booster-client/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const USER_FEATURE_KEY = 'accommodations';

export const featureAdapter = createEntityAdapter<AccommodationInList>({
  selectId: (model) => model.accommodationId,
});

export interface State extends EntityState<AccommodationInList> {
  selectedAccommodations: Nullable<Record<string, AccommodationInList>>;
  pagination: Nullable<ApiResponsePagination>;
  accommodationIds: string[];
  loading: boolean;
  error: unknown;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedAccommodations: null,
  accommodationIds: [],
  pagination: null,
  loading: false,
  error: null,
});
