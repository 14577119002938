import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromState from './search-engines.state';
import * as fromReducer from './search-engines.reducer';
import { SearchEnginesEffects } from './search-engines.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.USER_FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([SearchEnginesEffects]),
  ],
})
export class SearchEnginesStateModule {}
