import { SearchEngine } from '@booking-booster-client/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const USER_FEATURE_KEY = 'searchEngines';

export const featureAdapter = createEntityAdapter<SearchEngine>({
  selectId: (model) => model.searchEngineId,
});

export interface State extends EntityState<SearchEngine> {
  loading: boolean;
  error: unknown;
}

export const initialState: State = featureAdapter.getInitialState({
  loading: false,
  error: null,
});
