import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Nullable } from 'primeng/ts-helpers';
import { Organization } from '@booking-booster-client/models';
import { Router } from '@angular/router';
import {
  organizationActionsGroup,
  OrganizationSelectors,
  OrganizationStore,
  UserSessionStoreActions,
  UserSessionStoreSelectors,
} from '@booking-booster-client/root-store';

@Component({
  selector: 'bb-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrl: './organization-select.component.scss',
})
export class OrganizationSelectComponent implements OnInit, OnDestroy {
  organizations: Organization[] = [];

  organizationNameControl = this.fb.control(null, {
    validators: Validators.required,
  });

  organizationControl = this.fb.control<Nullable<string>>(null, {
    validators: Validators.required,
  });

  private closed$ = new Subject<boolean>();

  authStore$ = this.store.pipe(
    select(OrganizationSelectors.selectFeatureState),
  );

  organizations$ = this.store.pipe(
    select(OrganizationSelectors.selectOrganizations),
  );

  organizationId$ = this.store.pipe(
    select(UserSessionStoreSelectors.selectSelectedOrganizationId),
  );

  private subscription = new Subscription();

  public addMode = false;

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private store: Store<OrganizationStore.State>,
    private router: Router,
  ) {}

  setDefaultOrganization(organizationId: Nullable<string>) {
    const organizationSelected =
      organizationId || this.organizations?.[0]?.organizationId;
    this.organizationControl.setValue(organizationSelected);

    this.selectOnlyFirstOrganization(organizationSelected);
  }

  selectOnlyFirstOrganization(organizationSelected: string): void {
    if (this.organizations.length == 1) {
      if (organizationSelected) {
        this.store.dispatch(
          UserSessionStoreActions.setSelectedOrganizationRequest({
            organizationId: organizationSelected,
          }),
        );
      }

      this.ref.close();

      this.closed$.next(true);
    }
  }

  ngOnInit(): void {
    const combined = combineLatest([this.organizationId$, this.organizations$]);

    this.subscription.add(
      combined.subscribe(([organizationId, organizations]) => {
        this.organizations = organizations || [];
        this.setDefaultOrganization(organizationId);
      }),
    );
  }

  selectOrganization(): void {
    if (this.organizationControl.invalid) {
      this.organizationControl.markAllAsTouched();
      return;
    }
    const organizationId = this.organizationControl.value;

    if (organizationId) {
      this.store.dispatch(
        UserSessionStoreActions.setSelectedOrganizationRequest({
          organizationId,
        }),
      );
    }

    this.ref.close();

    if (this.config?.data?.redirectToHomePage) {
      this.router.navigate(['/']);
    }

    this.closed$.next(true);
  }

  createOrganization(): void {
    if (
      this.organizationNameControl.invalid ||
      !this.organizationNameControl.value
    ) {
      this.organizationNameControl.markAllAsTouched();
      return;
    }

    this.changeInAddMode();

    this.store.dispatch(
      organizationActionsGroup.createRequest({
        name: this.organizationNameControl.value,
      }),
    );
  }

  get closedChange$() {
    return this.closed$.asObservable();
  }

  changeInAddMode() {
    this.addMode = !this.addMode;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
