import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const balanceActionGroup = createActionGroup({
  source: 'Balance',
  events: {
    'Load Balance Request': emptyProps(),
    'Load Balance Success': props<{ balance: number }>(),
    'Load Balance Failure': props<{ error: unknown }>(),

    'Reset State': emptyProps(),
  },
});
