import { createReducer, on, Action } from '@ngrx/store';

import { balanceActionGroup as Actions } from './balance.actions';
import { initialState, State } from './balance.state';

const reducerFunction = createReducer(
  initialState,
  on(Actions.loadBalanceRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(Actions.loadBalanceSuccess, (state, { balance }) => ({
    ...state,
    loading: false,
    balance,
  })),

  on(Actions.loadBalanceFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(Actions.resetState, () => initialState),
);

export function reducer(state: State | undefined, action: Action) {
  return reducerFunction(state, action);
}
