import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, map, Observable, of } from 'rxjs';
import {
  Ad,
  AdGroup,
  ApiResponsePagination,
  Campaign,
  Keyword,
  LoadAdsRequest,
  LoadStatisticsTableRequest,
  OldAdsDataStructure,
  OldPropertyAd,
  OldPropertyAds,
  StatisticsTableRow,
  StatiticsTotals,
} from '@booking-booster-client/models';
import { paginate } from '../../fake-api-response';

import { HOTELS } from './accommodations.service';
import { generateSearchQuery, magic } from '@booking-booster-client/helpers';
import { groupBy, map as _map } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class AdsApiService {
  constructor(private httpClient: HttpClient) {}

  // [GET] properties/ads
  load(request: LoadAdsRequest): Observable<OldAdsDataStructure> {
    return this.httpClient
      .get<{
        campaigns: Campaign[];
      }>(`api/campaigns${generateSearchQuery(request)}`)
      .pipe(
        map((campaignsData) => {
          const { data, pagination } = paginate(
            campaignsToOldAds(campaignsData.campaigns),
            1,
            10,
          );
          return {
            data,
            meta: { pagination },
          };
        }),
      );
  }

  // [GET] properties/ads/statistics
  loadStatistics(request: LoadStatisticsTableRequest) {
    return this.httpClient
      .get<{
        rows: StatisticsTableRow[];
        totals: Partial<StatiticsTotals>;
      }>(`api/statistics/${request.type}${generateSearchQuery(request)}`)
      .pipe(
        map(({ rows, totals }) => ({
          totals,
          rows: rows.map((row) => ({
            ...row,
            active: !!magic(row.accommodationName + row.label, 0, 1),
          })),
        })),
      );
  }
}

function campaignsToOldAds(campaigns: Campaign[]): OldPropertyAds[] {
  const grouped = groupBy(campaigns, 'accommodationId');

  // Transform into the desired array structure
  const newArray = _map(grouped, (ads, accommodationId) => ({
    accommodationId,
    ads,
  }));

  return newArray.map((campaign) => {
    return {
      property: campaign.accommodationId as any,
      ads: campaign.ads
        .filter((ad) => {
          const singleAd = ad.adGroups[0];

          if (
            singleAd.ads?.[0].headlines?.[0]?.texts?.[0] &&
            singleAd.ads?.[0].descriptions?.[0]?.texts?.[0] &&
            singleAd.ads?.[0].headlines?.[0]?.texts?.[0]
          ) {
            return true;
          }

          return false;
        })
        .map((ad) => {
          const singleAd = ad.adGroups[0];
          return {
            name:
              singleAd.ads?.[0].headlines?.[0]?.texts?.[0] ?? 'Nessun testo',
            translations: generateTranslations(singleAd),
            phone_call_extension: '+39 3460312803',
            link_extension: '/home',
          };
        }),
    };
  });
}

function generateTranslations(adGroup: AdGroup) {
  return [
    generateTranslation(adGroup, 'it'),
    generateTranslation(adGroup, 'de'),
    generateTranslation(adGroup, 'es'),
    generateTranslation(adGroup, 'fr'),
    generateTranslation(adGroup, 'gb'),
    generateTranslation(adGroup, 'ru'),
    generateTranslation(adGroup, 'jp'),
  ];
}

function generateTranslation(adGroup: AdGroup, locale: string) {
  return {
    locale,
    ad: {
      title: adGroup.ads[0].headlines[0].texts[0],
      description: adGroup.ads[0].descriptions[0].texts[0],
      closure: '',
      web_site: `www.websiteurl.${locale}`,
    },
    keywords: adGroup.keywords.map((keyword) => keyword.combinations.join(',')),
  };
}
