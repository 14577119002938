/* eslint-disable @typescript-eslint/no-unused-vars */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  USER_FEATURE_KEY,
  State,
  featureAdapter,
} from './search-engines.state';

export const selectState = createFeatureSelector<State>(USER_FEATURE_KEY);

export const { selectAll } = featureAdapter.getSelectors(selectState);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);

export const selectEntity =
  featureAdapter.getSelectors(selectState).selectEntities;

export const selectItemById = (id: string) =>
  createSelector(selectEntity, (searchEngines) => {
    return searchEngines?.[id];
  });
