export interface Pricelist {
  label: string;
  commissionPercentage: number;
  activationPriceForEachRoomsRange: number;
  roomsRangeSize: number;
  id: string;
  isDefault?: boolean;
}

export interface QuotationPricelist
  extends Pick<
    Pricelist,
    | 'activationPriceForEachRoomsRange'
    | 'commissionPercentage'
    | 'id'
    | 'roomsRangeSize'
  > {}
