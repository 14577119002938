import { createAction, props } from '@ngrx/store';
import {
  LoadUserAccommodationsRequest,
  AccommodationInList,
} from '@booking-booster-client/models';

export const loadRequest = createAction(
  '[Accommodations] Load Request',
  props<{
    request: LoadUserAccommodationsRequest;
    disableLoading: boolean;
  }>(),
);
export const loadSuccess = createAction(
  '[Accommodations] Load Success',
  props<{
    ids: string[];
    accommodations: AccommodationInList[];
  }>(),
);
export const loadFailure = createAction(
  '[Accommodations] Load Failure',
  props<{ error: unknown }>(),
);

export const selectRequest = createAction(
  '[Accommodations] Select Request',
  props<{ selectedAccommodationsIDS: string[] }>(),
);
export const selectSuccess = createAction(
  '[Accommodations] Select Success',
  props<{
    selectedAccommodations: Record<string, AccommodationInList>;
  }>(),
);
export const selectFailure = createAction(
  '[Accommodations] Select Failure',
  props<{ error: unknown }>(),
);

export const deleteRequest = createAction(
  '[Accommodations] Delete Request',
  props<{
    accommodationId: string;
  }>(),
);
export const deleteSuccess = createAction(
  '[Accommodations] Delete Success',
  props<{
    accommodationId: string;
  }>(),
);
export const deleteFailure = createAction(
  '[Accommodations] Delete Failure',
  props<{ error: unknown }>(),
);

export const reset = createAction('[Accommodations] Reset');
