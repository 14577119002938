import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const supportedLanguagesActionGroup = createActionGroup({
  source: 'SupportedLanguages',
  events: {
    'Load Request': emptyProps(),

    'Load Success': props<{
      locals: string[];
    }>(),

    'Load Failure': props<{
      error: unknown;
    }>(),
    
    'Load Websites Languages Request': emptyProps(),

    'Load Websites Languages Success': props<{
      websitesLanguages: string[];
    }>(),

    'Load Websites Languages Failure': props<{
      error: unknown;
    }>(),
    
  },
});
