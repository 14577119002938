import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromReducer from './channel.reducer';
import { ChannelEffects } from './channel.effects';
import { FEATURE_KEY } from './channel.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([ChannelEffects]),
  ],
})
export class ChannelStoreModule {}
