import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, State } from './balance.state';

export const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectBalance = createSelector(
  selectState,
  (state: State) => state.balance,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);
