//THIS FILE CONTAINS STUFF TEMPORARILY NEEDED IN ORDER TO MAKE THE SOFTWARE WORK EVEN WITHOUT SOME BACKEND FUNCTIONALITY
//EVERY TEMPORARY STUFF BELONGS HERE, SO THAT IN THE FUTURE BY DELETING THIS FILE WE CAN FIX EVERYTHING WITH THE CORRECT APIs

export function magic(str: string, min: number, max: number) {
  // Ottieni un hash numerico dalla stringa

  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Converti il numero in un valore compreso tra 0 e 100

  let finalNumber = Math.abs(hash % (max + 1));

  while (finalNumber < min) {
    finalNumber += finalNumber;
  }

  return finalNumber;
}

export const FLAG_MAP: { [key: string]: string } = {
  ja: 'jp',
  zh: 'cn',
  en: 'gb',
  ru: 'ru',
  es: 'es',
  fr: 'fr',
  de: 'de',
  it: 'it',
};

export const DEFAULT_CURRENCY = 'EUR';
