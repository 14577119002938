import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebsitesByLocale } from '@booking-booster-client/models';

@Injectable({
  providedIn: 'root',
})
export class LanguagesApiService {
  constructor(private http: HttpClient) {}

  loadSupportedLanguages(): Observable<string[]> {
    return this.http.get<string[]>(`api/systems/supportedLanguages`);
  }

  loadWebsitesLanguages() {
    return this.http.get<{
      accommodationsWebsitesByLocales: WebsitesByLocale;
    }>(`api/accommodations/websitesByLocales`);
  }
}
