import { FormControl } from '@angular/forms';
import { ControlsOf } from './controls-of.model';

export interface Voucher {
  code: string;
  startDate: string;
  endDate: string;
  maxUsageCount: number;
  maxUsageCountByOrganization: number;
  overrideOrganizationPricelist: boolean;
  pricelistId: string;
  voucherId: string;
}

export interface VoucherForm
  extends ControlsOf<Omit<Voucher, 'voucherId' | 'startDate' | 'endDate'>> {
  startDate: FormControl<Date>;
  endDate: FormControl<Date>;
}
