import { CanActivateFn, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  filter,
  from,
  map,
  of,
  switchMap,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  UserSessionsState,
  UserSessionStoreSelectors,
} from '@booking-booster-client/root-store';

@Injectable()
export class SigninGuard {
  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private store: Store<UserSessionsState>,
  ) {}

  public get identityClaims() {
    return this.oauthService.getIdentityClaims() as { email: string };
  }

  public get email() {
    const claims = this.identityClaims;
    return claims ? claims.email : null;
  }

  canActivate: CanActivateFn = () => {
    return from(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(
      switchMap(() =>
        combineLatest([
          this.store.pipe(select(UserSessionStoreSelectors.selectUser)),
          this.store.pipe(select(UserSessionStoreSelectors.selectError)),
        ]).pipe(
          filter(([user, error]) => !!user || !!error), //undefined significa non caricato
          map(([user]) => {
            if (!user) {
              return true;
            }

            return this.router.createUrlTree(['/']);
          }),
          catchError(() => {
            return of(this.router.createUrlTree(['/']));
          }),
        ),
      ),
    );
  };
}
