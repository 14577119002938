export interface MapsOutput {
  lat: string;
  lon: string;
  place?: MapsOutputPlace;
}

export interface MapsOutputPlace {
  city?: string;
  zip_code?: string;
  address?: string;
  county?: string;
  country?: string;
  state?: string;
}
