export interface ApiResponse<T = unknown> {
  data: T;
  meta: ApiResponseMeta;
}

export interface ApiResponseMeta {
  pagination: ApiResponsePagination;
}

export interface ApiResponsePagination {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface Pagination {
  itemsPerPage: number;
  page: number;
  itemsTotal: number;
}
export interface Paginated<T> extends Pagination {
  items: T[];
}

export interface ApiResponseError {
  detail: string;
  status: number;
  title: string;
  traceId: string;
  type: string;
}
