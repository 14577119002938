import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY, UserSessionsState } from './user-session.state';

export const getUserState =
  createFeatureSelector<UserSessionsState>(USER_FEATURE_KEY);

export const selectUser = createSelector(
  getUserState,
  (state: UserSessionsState) => state.user,
);

export const selectPermissions = createSelector(
  getUserState,
  (state: UserSessionsState) => state.permissions,
);

export const selectError = createSelector(
  getUserState,
  (state: UserSessionsState) => state.error,
);

export const selectSelectedOrganizationId = createSelector(
  getUserState,
  (state: UserSessionsState) => state.selectedOrganizationId,
);
