import { SearchParams } from '@booking-booster-client/helpers';
import { Nullable } from '../types';

export interface LoadStatisticsTableRequest extends SearchParams {
  properties_ids: string[];
  date_from: Date;
  date_to: Date;
  type: 'ads' | 'keywords';
  locales?: Nullable<string[]>;
  page?: number;
  items_per_page?: number;
  filter?: string;
  sort?: Nullable<string>;
}
