import { SearchParams } from '@booking-booster-client/helpers';
import { Nullable } from '../types';
import { AccommodationsTag } from '../models';

export interface LoadUserAccommodationsRequest extends SearchParams {
  page?: number;
  search?: Nullable<string>;
  accommodationIds?: string[];
}

export interface LoadAccommodationsFilters
  extends Pick<LoadUserAccommodationsRequest, 'search'> {
  tags: AccommodationsTag[];
}
