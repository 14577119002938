export interface UserPermission {
  permissionId: number;
  name: PermissionLabel;
}

export interface Permission extends UserPermission {
  description: string;
  permissionLevels: PermissionLevel[];
}

export type PermissionLevel = 'System' | 'Organization' | 'Accommodation';

export type PermissionLabel =
  | 'ReadUser'
  | 'ReadCampaigns'
  | 'ReadStatistics'
  | 'ReadOrganization'
  | 'ReadActivity'
  | 'WriteActivity'
  | 'ReadTag'
  | 'WriteTag'
  | 'ReadRole'
  | 'WriteRole'
  | 'ReadAccommodation'
  | 'WriteAccommodation'
  | 'ReadSearchEngines'
  | 'WriteSearchEngines'
  | 'ReadPayments'
  | 'WritePayments'
  | 'ReadPricelists'
  | 'WritePricelists'
  | 'ReadVouchers'
  | 'WriteVouchers'
  | 'ReadSEMParams'
  | 'WriteSEMParams'
  | 'ReadCampaignTemplates'
  | 'WriteCampaignTemplates';
