export interface OsmPlace {
  place_id: number;
  licence: number;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  display_name: string;
  boundingbox: string[];
  address: {
    village?: string;
    county?: string;
    state?: string;
    postcode?: string;
    country?: string;
    country_code?: string;
    road?: string;
    town?: string;
    city?: string;
    pedestrian?: string;
    attraction?: string;
    suburb?: string;
    house_number?: string;
  };
  error?: string;
}
