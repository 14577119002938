import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromState from './user-session.state';
import * as fromReducer from './user-session.reducer';
import { UserEffects } from './user-session.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.USER_FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([UserEffects]),
  ],
})
export class UserSessionStateModule {}
