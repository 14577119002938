import { NgModule } from '@angular/core';
import {
  provideRouter,
  RouterModule,
  Routes,
  withComponentInputBinding,
} from '@angular/router';
import { AuthGuard } from './guards';
import { selectedOrganizationResolver } from './resolvers/selected-organization-resolver.resolve';
import { SigninGuard } from './guards/signin.guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.routes'),
    canActivate: [AuthGuard],
    data: { role: 'admin' },
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'onboard',
    loadChildren: () =>
      import('./modules/property-onboard/onboard.module').then(
        (m) => m.OnboardModuleWithRouting,
      ),
    canActivate: [AuthGuard],
    resolve: {
      organizationId: selectedOrganizationResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'signin',
    canActivate: [SigninGuard],
    runGuardsAndResolvers: 'always',

    loadChildren: () =>
      import('./modules/signin/signin.module').then((m) => m.SigninModule),
  },
  {
    path: 'simulator',
    loadChildren: () =>
      import('./modules/simulator/simulator.module').then(
        (m) => m.SimulatorModule,
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      organizationId: selectedOrganizationResolver,
    },
    runGuardsAndResolvers: 'always',

    loadChildren: () =>
      import('./modules/user-container/user-container.module').then(
        (m) => m.UserContainerModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [provideRouter(routes, withComponentInputBinding())],
})
export class AppRoutingModule {}
