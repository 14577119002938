import { createReducer, on, Action } from '@ngrx/store';
import * as Actions from './search-engines.actions';
import { featureAdapter, initialState, State } from './search-engines.state';

const reducerFunction = createReducer(
  initialState,
  on(Actions.loadRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.loadSuccess, (state, { searchEngines }) => {
    return featureAdapter.setAll(searchEngines, {
      ...state,
      loading: false,
    });
  }),
  on(Actions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(Actions.reset, () => initialState),
);

export function reducer(state: State | undefined, action: Action) {
  return reducerFunction(state, action);
}
