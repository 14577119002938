import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, State, featureAdapter } from './organization.state';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_KEY);

export const selectOrganizations =
  featureAdapter.getSelectors(selectFeatureState).selectAll;

export const selectEntity =
  featureAdapter.getSelectors(selectFeatureState).selectEntities;

export const selectItemById = (id: string) =>
  createSelector(selectEntity, (organizations) => {
    return organizations?.[id];
  });
