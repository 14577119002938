import {
  Channel,
  OnBoardAccommodation,
  OnBoardCurrentConnection,
} from '@booking-booster-client/models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Nullable } from 'primeng/ts-helpers';

export const FEATURE_KEY = 'channel';

export const featureAdapter: EntityAdapter<Channel> =
  createEntityAdapter<Channel>({
    selectId: (model) => model.channelId,
  });

export interface State extends EntityState<Channel> {
  channelId: Nullable<string>;
  connection: Nullable<OnBoardCurrentConnection>;
  accommodations: OnBoardAccommodation[];
  error: unknown;
  loading: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  channelId: null,
  connection: null,
  accommodations: [],
  error: null,
  loading: false,
});
