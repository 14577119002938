import { FormControl } from '@angular/forms';

export interface AccommodationsTag {
  tagId: string;
  name: string;
  color: string;
  accommodationIds: string[];
}

export interface AccommodationsTagForm {
  name: FormControl<string | null>;
  color: FormControl<string | null>;
  accommodationIds: FormControl<string[] | null>;
}
