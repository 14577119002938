import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromState from './accommodations.state';
import * as fromReducer from './accommodations.reducer';
import { AccommodationsEffects } from './accommodations.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.USER_FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([AccommodationsEffects]),
  ],
})
export class AccommodationsStateModule {}
