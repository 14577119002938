import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  EffectHooks,
  UserPermission,
  User,
} from '@booking-booster-client/models';

export const usersActionGroup = createActionGroup({
  source: 'User Session',
  events: {
    'Load Request': props<{ hooks?: EffectHooks }>(),
    'Load Success': props<{ user: User }>(),
    'Load Failure': props<{ error: unknown }>(),

    'Load Permissions Request': emptyProps(),
    'Load Permissions Success': props<{ permissions: UserPermission[] }>(),
    'Load Permissions Failure': props<{ error: unknown }>(),

    'Set Selected Organization Request': props<{
      organizationId: string;
    }>(),
    'Set Selected Organization Success': props<{
      organizationId: string;
    }>(),
    'Set Selected Organization Failure': props<{ error: unknown }>(),

    'Reset State': emptyProps(),
  },
});
