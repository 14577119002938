export interface Facility {
  facilityId: string;
  name: string;
  description: string;
  icon: string;
  color: string;
  translations: {
    locale: string;
    name: string;
    description: string;
  }[];
}
