import { Injectable } from '@angular/core';
import {
  Accommodation,
  AccommodationInList,
  LoadUserAccommodationsRequest,
  CreateTagRequest,
  AccommodationsTag,
} from '@booking-booster-client/models';
import { HttpClient } from '@angular/common/http';
import { generateSearchQuery, magic } from '@booking-booster-client/helpers';
import { map, max, of } from 'rxjs';

export const HOTELS: Record<number, Accommodation> = {
  1: {
    accommodationId: '1',
    id: 1,
    name: 'Hotel Cavagrande',
    logoUrl: 'https://booster.beddy.dev/assets/tmp/hotel1.jpeg',
    currency_symbol: '€',
    address: 'Via Cavagrande 1',
    country: 'Italia',
    state: 'Sicilia',
    county: 'Catania',
    city: 'Acireale',
    zipCode: '95024',
    latitude: 37.6111331,
    longitude: 15.1674326,
    phone: '+39123456789',
    email: 'info@miohotel.dev',
    facebook: 'pagina-facebook',
    instagram: '#paginafacebook',
    tags_ids: [1, 2],
    externalPoiCodes: [],
    activityIds: ['asd'],
    services_ids: [],
    messages: [
      'Abbiamo alcuni suggerimenti per migliorare il tuo sito internet. Attualmente hai un punteggio di 50/100, effettua un <u>nuovo test</u>',
    ],
    facilityIds: [],
    roomCount: 1,
    stars: 0,
    threads: 'tre',
    tiktok: 'tik',
    type: 'hotel',
    websites: [],
    x: 'x',
    website_quality: 70,
    revenue: 480000,
    reservations_count: 126,
    conversions_count: 280,
    points_of_interest_count: 10,
    tags_count: 2,
    activityCount: 4,
    website_url: true,
    booking_engine: true,
    parity_rate: false,
  },
  2: {
    accommodationId: '2',
    id: 2,
    name: 'Flora Apartment',
    logoUrl: 'https://booster.beddy.dev/assets/tmp/hotel2.jpeg',
    currency_symbol: '€',
    address: 'Via dei Fiori 15',
    country: 'Italia',
    state: 'Sicilia',
    county: 'Catania',
    city: 'Acireale',
    zipCode: '95024',
    latitude: 37.6111331,
    longitude: 15.1674326,
    phone: '+39123456789',
    email: 'info@miohotel.dev',
    facebook: 'pagina-facebook',
    instagram: '#paginafacebook',
    tags_ids: [1],
    externalPoiCodes: [],
    activityIds: ['asd'],
    services_ids: [],
    messages: [
      'Abbiamo rilevato che la Germania è uno dei tuoi mercati più performanti, ma il tuo booking engine non risulta in tedesco, ti suggeriamo di intervenire',
    ],
    facilityIds: [],
    roomCount: 1,
    stars: 0,
    threads: 'tre',
    tiktok: 'tik',
    type: 'hotel',
    websites: [],
    x: 'x',
    website_quality: 70,
    revenue: 480000,
    reservations_count: 126,
    conversions_count: 280,
    points_of_interest_count: 10,
    tags_count: 2,
    activityCount: 4,
    website_url: true,
    booking_engine: true,
    parity_rate: false,
  },
  3: {
    accommodationId: '3',
    id: 3,
    name: 'Corte dei Sari',
    logoUrl: 'https://booster.beddy.dev/assets/tmp/hotel3.jpeg',
    currency_symbol: '€',
    address: 'Via Saromelo 45',
    country: 'Italia',
    state: 'Sicilia',
    county: 'Catania',
    city: 'Acireale',
    zipCode: '95024',
    latitude: 37.6111331,
    longitude: 15.1674326,
    phone: '+39123456789',
    email: 'info@miohotel.dev',
    facebook: 'pagina-facebook',
    instagram: '#paginafacebook',
    tags_ids: [],
    externalPoiCodes: [],
    activityIds: [],
    services_ids: [],
    facilityIds: [],
    roomCount: 1,
    stars: 0,
    threads: 'tre',
    tiktok: 'tik',
    type: 'hotel',
    websites: [],
    x: 'x',
    website_quality: 70,
    revenue: 480000,
    reservations_count: 126,
    conversions_count: 280,
    points_of_interest_count: 10,
    tags_count: 2,
    activityCount: 4,
    website_url: true,
    booking_engine: true,
    parity_rate: false,
  },
};

@Injectable({
  providedIn: 'root',
})
export class AccommodationsService {
  constructor(private http: HttpClient) {}

  loadAccommodations(request: LoadUserAccommodationsRequest) {
    if (request.accommodationIds && !request.accommodationIds.length) {
      return of<{ accommodations: AccommodationInList[] }>({
        accommodations: [],
      });
    }

    return this.http
      .get<{
        accommodations: AccommodationInList[];
      }>(`api/accommodations${generateSearchQuery(request)}`)
      .pipe(
        map((response) => ({
          accommodations: response.accommodations.map((acc) => ({
            ...acc,
            revenue: magic('revenue' + acc.accommodationId, 50000, 100000),
            bookings: magic('bookings' + acc.accommodationId, 100, 200),
            conversions: magic('conversions' + acc.accommodationId, 50, 100),
          })),
        })),
      );
  }

  getDetails(accommodationId: string) {
    return this.http.get<{ accommodation: Accommodation }>(
      `api/accommodations/${accommodationId}`,
    );
  }

  getTags() {
    return this.http.get<{ tags: AccommodationsTag[] }>('api/tags');
  }

  createTag(request: CreateTagRequest) {
    return this.http.post<AccommodationsTag>(`api/tags`, request);
  }

  delete(accommodationId: string) {
    return this.http.delete(`api/accommodations/${accommodationId}`);
  }

  update(accommodationId: string, propertyChanges: Partial<Accommodation>) {
    return this.http.put(
      `api/accommodations/${accommodationId}`,
      propertyChanges,
    );
  }

  removeFacility(accommodationId: string, facilityId: string) {
    return this.http.delete(
      `api/accommodations/${accommodationId}/facilities/${facilityId}`,
    );
  }

  addFacility(accommodationId: string, facilityId: string) {
    return this.http.post(
      `api/accommodations/${accommodationId}/facilities/${facilityId}`,
      {},
    );
  }

  removeActivity(accommodationId: string, activityId: string) {
    return this.http.delete(
      `api/accommodations/${accommodationId}/activities/${activityId}`,
    );
  }

  addActivity(accommodationId: string, activityId: string) {
    return this.http.post(
      `api/accommodations/${accommodationId}/activities/${activityId}`,
      {},
    );
  }

  removePoi(accommodationId: string, poiId: string) {
    return this.http.delete(
      `api/accommodations/${accommodationId}/pois/${poiId}`,
    );
  }

  addPoi(accommodationId: string, poiId: string) {
    return this.http.post(
      `api/accommodations/${accommodationId}/pois/${poiId}`,
      {},
    );
  }
}
