import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  GetQuotationRequest,
  MakePaymentRequest,
  PaymentInfo,
  Quotation,
} from '@booking-booster-client/models';

@Injectable({
  providedIn: 'root',
})
export class PaymentsApiService {
  constructor(private httpClient: HttpClient) {}

  getQuotation(request: GetQuotationRequest) {
    return this.httpClient.post<Quotation>(`api/quotations`, request);
  }

  payment(request: MakePaymentRequest) {
    return this.httpClient.post<PaymentInfo>(`api/payments`, request);
  }
}
