export interface Poi {
  id: string;
  name: string;
  iconColor: string;
  iconUri: string;
  address: string;
  place_id: string;
  latitude: number;
  longitude: number;
}
