import { createReducer, on, Action } from '@ngrx/store';
import * as Actions from './accommodations.actions';
import { featureAdapter, initialState, State } from './accommodations.state';

const reducerFunction = createReducer(
  initialState,
  on(Actions.loadRequest, (state, { disableLoading }) => ({
    ...state,
    loading: !disableLoading,
    error: null,
  })),
  on(Actions.loadSuccess, (state, { ids, accommodations }) => {
    return featureAdapter.setAll(accommodations, {
      ...state,
      accommodationIds: ids,
      loading: false,
    });
  }),
  on(Actions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(Actions.selectRequest, (state, { selectedAccommodationsIDS }) => ({
    ...state,
    selectedAccommodations: selectedAccommodationsIDS.reduce(
      (selectedAccommodations, accommodationsID) => ({
        ...selectedAccommodations,
        [accommodationsID]: {
          ...state.entities[accommodationsID],
          id: accommodationsID,
        },
      }),
      {},
    ),
  })),

  on(Actions.deleteRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.deleteSuccess, (state, { accommodationId }) => {
    return featureAdapter.removeOne(accommodationId, {
      ...state,
      accommodationIds: state.accommodationIds.filter(
        (id) => id !== accommodationId,
      ),
      loading: false,
    });
  }),
  on(Actions.deleteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(Actions.reset, () => initialState),
);

export function reducer(state: State | undefined, action: Action) {
  return reducerFunction(state, action);
}
