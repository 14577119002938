import { Organization } from '@booking-booster-client/models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const FEATURE_KEY = 'organization';

export const featureAdapter: EntityAdapter<Organization> =
  createEntityAdapter<Organization>({
    selectId: (model) => model.organizationId,
  });

export interface State extends EntityState<Organization> {
  loading: boolean;
  error: unknown;
}

export const initialState: State = featureAdapter.getInitialState({
  loading: false,
  error: null,
});
