import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AccommodationsTag,
  CreateTagRequest,
  EffectHooks,
} from '@booking-booster-client/models';

export const accommodationTagsActions = createActionGroup({
  source: 'Accommodations Tags',
  events: {
    'Load Request': emptyProps(),
    'Load Success': props<{ propertiesTags: AccommodationsTag[] }>(),
    'Load Failure': props<{ error: unknown }>(),

    'Create Request': props<{
      request: CreateTagRequest;
      hooks?: EffectHooks;
    }>(),
    'Create Success': props<{ tag: AccommodationsTag }>(),
    'Create Failure': props<{ error: unknown }>(),

    Reset: emptyProps(),
  },
});
