import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadExpensesRequest } from '@booking-booster-client/models';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpensesService {
  constructor(private http: HttpClient) {}

  load(request: LoadExpensesRequest) {
    return of({
      pagination: {
        itemsPerPage: 10,
        page: 1,
        itemsTotal: 25,
      },
      items: [
        {
          id: '1',
          payment_date: '2024-11-01',
          amount: 150.5,
          outgo: true,
          searchEngine: 'Google',
        },
        {
          id: '2',
          payment_date: '2024-11-02',
          amount: 75.0,
          outgo: false,
        },
        {
          id: '3',
          payment_date: '2024-11-03',
          amount: 200.0,
          outgo: true,
          searchEngine: 'Google',
        },
        {
          id: '4',
          payment_date: '2024-11-04',
          amount: 90.25,
          outgo: true,
        },
        {
          id: '5',
          payment_date: '2024-11-05',
          amount: 120.0,
          outgo: false,
          searchEngine: 'Google',
        },
        {
          id: '6',
          payment_date: '2024-11-06',
          amount: 50.75,
          outgo: true,
        },
        {
          id: '7',
          payment_date: '2024-11-07',
          amount: 300.0,
          outgo: false,
          searchEngine: 'Yahoo',
        },
        {
          id: '8',
          payment_date: '2024-11-08',
          amount: 85.5,
          outgo: true,
        },
        {
          id: '9',
          payment_date: '2024-11-09',
          amount: 250.0,
          outgo: false,
        },
        {
          id: '10',
          payment_date: '2024-11-10',
          amount: 180.75,
          outgo: true,
          searchEngine: 'Google',
        },
      ],
    });
  }
}
