export type SEMParamsKeyLabels =
  | 'HISTORY_NUMBER_OF_YEARS'
  | 'HISTORY_NUMBER_OF_WEEKS'
  | 'HISTORY_START_WEIGHT'
  | 'HISTORY_WEIGHT_LOSS_PER_YEAR'
  | 'HISTORY_WEIGHT_LOSS_PER_WEEK'
  | 'HISTORY_WEEK_RESERVATIONS_WEIGHT'
  | 'HISTORY_WEEK_WEBSITE_RESERVATIONS_WEIGHT'
  | 'HISTORY_WEEK_AVG_DAILY_COST_WEIGHT'
  | 'HISTORY_WEEK_AVAILABILITY_WEIGHT'
  | 'HISTORY_WEBSITE_LANGUAGES_WEIGHT'
  | 'HISTORY_LOST_QI_BUDGET_WEIGHT'
  | 'HISTORY_ROOMS_COUNT_LIMIT'
  | 'HISTORY_ROOMS_COUNT_LIMIT_WEIGHT_LOSS'
  | 'CONVERSION_RATE_NUMBER_OF_WEEKS'
  | 'CONVERSION_RATE_START_WEIGHT'
  | 'CONVERSION_RATE_WEIGHT_LOSS_PER_WEEK'
  | 'COST_INCIDENCE_LIMIT';

export type SemParamType = 'integer' | 'percentage';

export const SEM_PARAMS_INFO: {
  [key: number]: {
    label: SEMParamsKeyLabels;
    type: SemParamType;
    min: number;
    max: number;
  };
} = {
  1: { label: 'HISTORY_NUMBER_OF_YEARS', type: 'integer', min: 1, max: 5 },
  2: { label: 'HISTORY_NUMBER_OF_WEEKS', type: 'integer', min: 1, max: 5 },
  3: { label: 'HISTORY_START_WEIGHT', type: 'percentage', min: 1, max: 100 },
  4: {
    label: 'HISTORY_WEIGHT_LOSS_PER_YEAR',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  5: {
    label: 'HISTORY_WEIGHT_LOSS_PER_WEEK',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  6: {
    label: 'HISTORY_WEEK_RESERVATIONS_WEIGHT',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  7: {
    label: 'HISTORY_WEEK_WEBSITE_RESERVATIONS_WEIGHT',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  8: {
    label: 'HISTORY_WEEK_AVG_DAILY_COST_WEIGHT',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  9: {
    label: 'HISTORY_WEEK_AVAILABILITY_WEIGHT',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  10: {
    label: 'HISTORY_WEBSITE_LANGUAGES_WEIGHT',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  11: {
    label: 'HISTORY_LOST_QI_BUDGET_WEIGHT',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  12: { label: 'HISTORY_ROOMS_COUNT_LIMIT', type: 'integer', min: 1, max: 20 },
  13: {
    label: 'HISTORY_ROOMS_COUNT_LIMIT_WEIGHT_LOSS',
    type: 'percentage',
    min: 0,
    max: 100,
  },
  14: {
    label: 'CONVERSION_RATE_NUMBER_OF_WEEKS',
    type: 'integer',
    min: 1,
    max: 5,
  },
  15: {
    label: 'CONVERSION_RATE_START_WEIGHT',
    type: 'percentage',
    min: 1,
    max: 100,
  },
  16: {
    label: 'CONVERSION_RATE_WEIGHT_LOSS_PER_WEEK',
    type: 'integer',
    min: 0,
    max: 100,
  },
  17: { label: 'COST_INCIDENCE_LIMIT', type: 'integer', min: 0, max: 100 },
};
