import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, State } from './supported-languages.state';

export const getLocalState = createFeatureSelector<State>(FEATURE_KEY);

export const selectLocals = createSelector(
  getLocalState,
  (state: State) => state.locals,
);

export const selectError = createSelector(
  getLocalState,
  (state: State) => state.error,
);

export const selectWebsitesLanguages = createSelector(
  getLocalState,
  (state: State) => state.websitesLanguages,
);
