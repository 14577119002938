import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromState from './balance.state';
import * as fromReducer from './balance.reducer';
import { BalanceEffects } from './balance.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([BalanceEffects]),
  ],
})
export class BalanceStoreModule {}
