import { Injectable } from '@angular/core';
import { BalanceService } from '@booking-booster-client/api';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { balanceActionGroup as fromActions } from './balance.actions';
import { HandlerMessageService } from '@booking-booster-client/services';

@Injectable()
export class BalanceEffects {
  constructor(
    private actions$: Actions,
    private apiService: BalanceService,
    private messageService: HandlerMessageService,
  ) {}

  loadBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadBalanceRequest),
      switchMap(() =>
        this.apiService.loadBalance().pipe(
          map((balance) => {
            return fromActions.loadBalanceSuccess({ balance });
          }),
          catchError((error) => {
            this.messageService.showBackendErrorMessage(error);
            return of(fromActions.loadBalanceFailure(error));
          }),
        ),
      ),
    ),
  );
}
