export interface StatisticsTableRow {
  accommodationName: string;
  conversionPercentage: number;
  costIncidence: number;
  cpc: number;
  ctr: number;
  label: string;
  active: boolean;
  locale: string;
  lostImpressionShareBudget: number;
  lostImpressionShareRank: number;
  phoneCalls: number;
  reservationCost: number;
  reservations: number;
  revenue: number;
  templateId: string;
  totalExpense: number;
  views: number;
  visits: number;
}

export interface StatiticsTotals {
  conversionPercentage: number;
  costIncidence: number;
  cpc: number;
  ctr: number;
  lostImpressionShareBudget: number;
  lostImpressionShareRank: number;
  phoneCalls: number;
  reservationCost: number;
  reservations: number;
  revenue: number;
  totalExpense: number;
  views: number;
  visits: number;
}
