import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Activity,
  LoadActivitiesRequest,
  Paginated,
} from '@booking-booster-client/models';
import { generateSearchQuery } from '@booking-booster-client/helpers';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  constructor(private http: HttpClient) {}

  getIndex(request?: LoadActivitiesRequest) {
    return this.http.get<Paginated<Activity>>(
      `api/activities${generateSearchQuery(request)}`,
    );
  }
}
