import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

export function propertiesFilter(): MonoTypeOperatorFunction<number[]> {
  return pipe(
    filter((propertiesIDS) => !!propertiesIDS?.length),
    distinctUntilChanged((previousIDS, currentIDS) =>
      isEqual(previousIDS.slice().sort(), currentIDS.slice().sort()),
    ),
  );
}
