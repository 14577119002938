import { SearchParams } from '@booking-booster-client/helpers';

export interface SearchEngine {
  icon: string;
  label: string;
  searchEngineId: string;
}

export interface SearchEngineConnectionsLoadRequest extends SearchParams {
  page?: number;
  itemsPerPage?: number;
}

export interface SearchEngineConnectiosBudgetsLoadRequest extends SearchParams {
  searchEngineConnectionId: string;
  page?: number;
  itemsPerPage?: number;
}

export interface SearchEngineConnection {
  organizationId: string;
  searchEngineConnectionId: string;
  searchEngineId: string;
}

export interface SearchEngineConnectionBudget {
  accommodationIds: string[];
  amount: number;
  externalBudgetCode?: string;
  isOrganization: boolean;
  searchEngineBudgetId: string;
  searchEngineConnectionId: string;
}

export interface SearchEngineConnectionBudgetCreateRequest {
  amount: number;
  type: number;
  accommodationIds?: string[];
  searchEngineConnectionId: string;
}
