export interface Activity {
  activityId: string;
  organizationId: string;
  name: string;
  description: string;
  type: number;
  longitude: number;
  latitude: number;
  startDate: string;
  endDate: string;
  translations: ActivityTranslation[];
}

interface ActivityTranslation {
  locale: string;
  name: string;
  description: string;
}
