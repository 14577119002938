import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  LoadVouchersRequest,
  Paginated,
  Voucher,
} from '@booking-booster-client/models';
import { generateSearchQuery } from '@booking-booster-client/helpers';

@Injectable({
  providedIn: 'root',
})
export class VouchersApiService {
  constructor(private httpClient: HttpClient) {}

  load(request: LoadVouchersRequest) {
    return this.httpClient.get<Paginated<Voucher>>(
      `api/vouchers${generateSearchQuery(request)}`,
    );
  }

  create(request: Omit<Voucher, 'voucherId'>) {
    return this.httpClient.post<boolean>(`api/vouchers`, request);
  }

  delete(id: string) {
    return this.httpClient.delete<boolean>(`api/vouchers/${id}`);
  }

  edit(request: Voucher) {
    return this.httpClient.put<boolean>(
      `api/vouchers/${request.voucherId}`,
      request,
    );
  }
}
