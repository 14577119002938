import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadPoisRequest, Poi } from '@booking-booster-client/models';
import { generateSearchQuery } from '@booking-booster-client/helpers';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PoisApiService {
  constructor(private httpClient: HttpClient) {}

  get(request: LoadPoisRequest) {
    return this.httpClient
      .get<{
        pois: Poi[];
      }>(`api/pois/nearbysearch${generateSearchQuery(request)}`)
      .pipe(
        map((value) => {
          const { search } = request;
          if (search && typeof search === 'string') {
            return {
              ...value,
              pois: value.pois.filter((poi) =>
                poi.name.toLowerCase().includes(search.toLowerCase()),
              ),
            };
          }

          return value;
        }),
      );
  }
}
