import { OnBoardConnectionSource } from '../types';

export interface CreateOnBoardConnectionRequest {
  source_type: OnBoardConnectionSource | null | undefined;
  source_id: string | null | undefined;
  username?: string;
  password?: string;
  codes?: string[];
}

export interface ConnectionRequest {
  channelId: string;
  connectionParams: { key: string; type: string; value: string }[];
}
