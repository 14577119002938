export type AdGroupTemplateRelation =
  | 'poi'
  | 'facility'
  | 'activity'
  | 'accommodation';

export interface AdGroupTemplate {
  id: string;
  label: string;
  relation: AdGroupTemplateRelation;
}

export interface AdGroupTemplateDetail extends AdGroupTemplate {
  adTemplates: AdTemplate[];
  keywordTemplates: KeyWordTemplate[];
}

export interface AdTemplate {
  descriptions: AdTemplateDescription[];
  headlines: AdTemplateHeadline[];
  id: string;
  label: string;
}

export interface KeyWordTemplate {
  contents: KeyWordTemplateContents[];
  id: string;
  label: string;
}

export interface AdTemplateDescription {
  id: string;
  text: string;
  locale: string;
}

export interface AdTemplateHeadline {
  id: string;
  text: string;
  locale: string;
}

export interface KeyWordTemplateContents {
  id: string;
  content: string;
  locale: string;
}
