export const FEATURE_KEY = 'supportedLanguages';

export interface State {
  locals: string[];
  websitesLanguages: string[];
  error: unknown;
}

export const initialState: State = {
  locals: [],
  websitesLanguages: [],
  error: null,
};
