import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthApiService } from '@booking-booster-client/api';
import {
  effectHooks,
  ORGANIZATION_SELECTED,
} from '@booking-booster-client/helpers';
import { usersActionGroup as fromActions } from './user-session.actions';
import { LocalStorageService } from '@booking-booster-client/services';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userApiService: AuthApiService,
    private localStorageService: LocalStorageService,
  ) {}

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPermissionsRequest),
      switchMap(() =>
        this.userApiService.getUserPermissions().pipe(
          map(({ permissions }) => {
            return fromActions.loadPermissionsSuccess({ permissions });
          }),
          catchError((error) => {
            return of(fromActions.loadPermissionsFailure(error));
          }),
        ),
      ),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ hooks }) =>
        this.userApiService.getUser().pipe(
          effectHooks(hooks),
          map((user) => {
            return fromActions.loadSuccess({ user });
          }),
          catchError((error) => {
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  setOrganizationId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSelectedOrganizationRequest),
      map(({ organizationId }) => {
        this.localStorageService.setItem(ORGANIZATION_SELECTED, organizationId);
        return fromActions.setSelectedOrganizationSuccess({ organizationId });
      }),
      catchError((error) => {
        return of(fromActions.setSelectedOrganizationFailure(error));
      }),
    ),
  );
}
