import { ConnectionParams } from './connection-params.model';

export interface DataChannels {
  channels: Channel[];
}

export interface Channel {
  name: string;
  channelId: string;
  connectionParams: ConnectionParams[];
}
