import { OldAd } from './ad.model';
import { ApiResponsePagination } from './api-response.model';

export interface OldAdsDataStructure {
  data: OldPropertyAds[];
  meta: { pagination: ApiResponsePagination };
}
export interface OldPropertyAds {
  property: string;
  ads: OldPropertyAd[];
}

export interface OldPropertyAd {
  name: string;
  translations: Array<{
    locale: string;
    ad: OldAd;
    keywords: string[];
  }>;
  phone_call_extension: string;
  link_extension: string;
}

export interface Campaign {
  locale: string;
  templateId: string;
  organizationId: string;
  accommodationId: string;
  adGroups: AdGroup[];
}

export interface AdGroup {
  templateId: string;
  ads: Ad[];
  keywords: Keyword[];
}

export interface Ad {
  templateId: string;
  headlines: {
    templateId: string;
    texts: string[];
  }[];
  descriptions: {
    templateId: string;
    texts: string[];
  }[];
}

export interface Keyword {
  templateId: string;
  combinations: string[];
}
