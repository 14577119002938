import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccommodationTarget } from '@booking-booster-client/models';
import { generateSearchQuery } from '@booking-booster-client/helpers';

@Injectable({
  providedIn: 'root',
})
export class AccommodationTargetsService {
  constructor(private http: HttpClient) {}

  load(accommodationIds: string[]) {
    return this.http.get<{ targets: AccommodationTarget[] }>(
      `api/accommodationsTargets${generateSearchQuery({ accommodationIds })}`,
    );
  }

  toggle(accommodationTargetId: string, active: boolean) {
    return this.http.put<boolean>(
      `api/accommodationsTargets/${accommodationTargetId}/toggle`,
      { active },
    );
  }
}
