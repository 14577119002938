import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromReducer from './supported-languages.reducer';
import { SupportedLanguagesEffects } from './supported-languages.effects';
import { FEATURE_KEY } from './supported-languages.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([SupportedLanguagesEffects]),
  ],
})
export class SupportedLanguagesStoreModule {}
