import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { upperFirst } from 'lodash-es';
import { ConfirmationService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationWrapperService {
  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private store: Store,
  ) {}

  showDeleteConfirm(accept: () => unknown, item = 'item') {
    this.confirmationService.confirm({
      message: upperFirst(
        this.translate.instant('confirm_delete_message', {
          name: this.translate.instant(item),
        }),
      ),
      header: upperFirst(this.translate.instant('warning')),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      accept,
      reject: () => {
        this.confirmationService.close();
      },
    });
  }
}
