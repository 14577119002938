import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type Theme = 'default' | 'dark';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private currentTheme: Theme = 'default';

  private currentTheme$ = new BehaviorSubject<Theme>(this.currentTheme);

  set(theme: Theme) {
    this.updateTheme(theme);
  }

  get() {
    return this.currentTheme$.asObservable();
  }

  getCurrent() {
    return this.currentTheme;
  }

  private updateTheme(theme: Theme) {
    document.body.setAttribute('data-theme-version', theme);

    // Waiting for CSS propagation
    setTimeout(() => {
      this.currentTheme = theme;
      this.currentTheme$.next(theme);
    }, 100);
  }
}
