import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { AccommodationsService } from '@booking-booster-client/api';
import * as fromActions from './accommodations.actions';
import * as fromState from './accommodations.state';
import * as fromSelectors from './accommodations.selectors';
import { Action, select, Store } from '@ngrx/store';
import { intersection } from 'lodash-es';
import { Router } from '@angular/router';

@Injectable()
export class AccommodationsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromState.State>,
    private accommodationsApiService: AccommodationsService,
    private router: Router,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      withLatestFrom(
        this.store.pipe(select(fromSelectors.selectSelectedAccommodationIds)),
      ),
      switchMap(([{ request }, selectedAccommodationsIds]) => {
        return this.accommodationsApiService.loadAccommodations(request).pipe(
          mergeMap(({ accommodations }) => {
            if (accommodations.length === 0 && !request?.search) {
              this.router.navigate(['/onboard']);
            }

            const ids = accommodations.map(
              (accommodation) => accommodation.accommodationId,
            );

            const effects: Action[] = [
              fromActions.loadSuccess({
                ids,
                accommodations,
              }),
            ];

            const newSelectedIds = intersection(
              selectedAccommodationsIds,
              accommodations.map(
                (accommodation) => accommodation.accommodationId,
              ),
            );

            effects.push(
              fromActions.selectRequest({
                selectedAccommodationsIDS: newSelectedIds.length
                  ? newSelectedIds
                  : ids,
              }),
            );

            return effects;
          }),
          catchError((error) => {
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ accommodationId }) =>
        this.accommodationsApiService.delete(accommodationId).pipe(
          map(() => {
            return fromActions.deleteSuccess({
              accommodationId,
            });
          }),
          catchError((error) => {
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
