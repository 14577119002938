import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { PrimeNGConfig } from 'primeng/api';
import { authCodeFlowConfig } from './auth.config';
import { select, Store } from '@ngrx/store';
import { combineLatest, filter, Subscription, take } from 'rxjs';
import {
  BalanceStoreActions,
  SearchEnginesStoreActions,
  supportedLanguagesActionGroup,
  UserSessionStoreActions,
  UserSessionStoreSelectors,
} from '@booking-booster-client/root-store';

@Component({
  selector: 'bb-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private store = inject(Store);

  private subs = new Subscription();

  selectedOrganization$ = this.store.pipe(
    select(UserSessionStoreSelectors.selectSelectedOrganizationId),
  );

  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private oauthService: OAuthService,
  ) {}

  ngOnInit(): void {
    this.translateService.get('primeng').subscribe((res) => {
      this.config.setTranslation(res);
    });

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    //When the token expired the token is refreshed, the token expire after 1 hour
    this.oauthService.setupAutomaticSilentRefresh({
      disableIdTokenTimer: true,
    });

    //Debug stuff to monitor

    this.oauthService.events.subscribe((event) => {
      console.log(event);
    });

    this.oauthService.events
      .pipe(
        filter(
          (event) =>
            event.type === 'silent_refresh_timeout' ||
            event.type === 'invalid_nonce_in_state',
        ),
      )
      .subscribe(() => {
        this.oauthService.initImplicitFlow();
      });

    this.oauthService.events
      .pipe(filter((event) => event.type === 'token_error'))
      .subscribe(() => {
        this.oauthService.revokeTokenAndLogout();
      });

    if (this.oauthService.hasValidAccessToken()) {
      this.loadBasicInfo();
    } else {
      this.oauthService.events
        .pipe(
          filter((event) => event.type === 'token_received'),
          take(1),
        )
        .subscribe(() => {
          this.loadBasicInfo();
        });
    }

    this.subs.add(
      this.selectedOrganization$.subscribe((organization) => {
        if (organization) {
          this.loadOrganizationInfo();
        }
      }),
    );
  }

  loadBasicInfo() {
    this.store.dispatch(UserSessionStoreActions.loadRequest({}));
    combineLatest([
      this.store.pipe(select(UserSessionStoreSelectors.selectUser)),
      this.store.pipe(select(UserSessionStoreSelectors.selectError)),
    ])
      .pipe(
        filter(([user, error]) => !!user || !!error),
        take(1),
      )
      .subscribe(([user]) => {
        if (user) {
          this.store.dispatch(supportedLanguagesActionGroup.loadRequest());
          this.store.dispatch(SearchEnginesStoreActions.loadRequest());
        }
      });
  }

  loadOrganizationInfo() {
    this.store.dispatch(UserSessionStoreActions.loadPermissionsRequest());
    this.store.dispatch(BalanceStoreActions.loadBalanceRequest());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
