import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrganizationApiService } from '@booking-booster-client/api';
import { organizationActionsGroup } from './organization.actions';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationApiService,
  ) {}

  createOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationActionsGroup.createRequest),
      switchMap(({ name }) => {
        return this.organizationService.createOrganization(name).pipe(
          map((organization) => {
            return organizationActionsGroup.createSuccess({
              organization: {
                ...organization,
              },
            });
          }),
          catchError((error) => {
            return of(organizationActionsGroup.createFailure(error));
          }),
        );
      }),
    ),
  );

  loadOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationActionsGroup.loadRequest),
      switchMap(() =>
        this.organizationService.getOrganizations().pipe(
          map((data) => {
            return organizationActionsGroup.loadSuccess({
              dataOrganizations: data,
            });
          }),
          catchError((error) => {
            return of(organizationActionsGroup.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
