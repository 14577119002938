import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BalanceService {
  constructor(private httpClient: HttpClient) {}

  loadBalance() {
    return of(6900);
  }
}
