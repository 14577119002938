export interface DataAccomodations {
  accommodations: OnBoardAccommodation[];
}

export interface OnBoardAccommodation {
  accommodationId?: string;
  id: number;
  name: string;
  roomCount: number;
  logo: string;
  country: string;
  state: string;
  county: string;
  city: string;
  address: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  phone: string;
  email: string;
  facebook: string;
  instagram: string;
  tiktok: string;
  x: string;
  threads: string;
  stars: number;
  type: string;
  facilities: OnBoardFacility[];
}

interface OnBoardFacility {
  code: string;
  name: string;
}
