import { UserOrganization } from './user-organization.model';

export interface Organization {
  name: string;
  ownerUserId: string;
  organizationId: string;
  domainEvents: string[];
  users: UserOrganization[];
}

export interface DataOrganization {
  organizations: Organization[];
}
