import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SemParam, SemParamEditRequest } from '@booking-booster-client/models';

@Injectable({
  providedIn: 'root',
})
export class SemParamsService {
  constructor(private httpClient: HttpClient) {}

  load() {
    return this.httpClient.get<{ semParams: SemParam[] }>(`api/semparams`);
  }

  edit(request: SemParamEditRequest) {
    return this.httpClient.post<SemParam>(`api/semparams`, request);
  }
}
