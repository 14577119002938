import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AdGroupTemplate,
  AdGroupTemplateDetail,
  AdTemplate,
  CampaignTemplate,
  KeyWordTemplate,
} from '@booking-booster-client/models';

@Injectable({
  providedIn: 'root',
})
export class CampaignsTemplatesService {
  constructor(private httpClient: HttpClient) {}

  load() {
    return this.httpClient.get<{ campaignTemplates: CampaignTemplate[] }>(
      `api/campaignTemplates`,
    );
  }

  loadAdGroupsTemplates(campaignId: string) {
    return this.httpClient.get<{ adGroupTemplates: AdGroupTemplate[] }>(
      `api/campaignTemplates/${campaignId}/adGroupsTemplates`,
    );
  }

  loadAdGroupTemplateDetail(campaignId: string, adGroupTemplateId: string) {
    return this.httpClient.get<AdGroupTemplateDetail>(
      `api/campaignTemplates/${campaignId}/adGroupsTemplates/${adGroupTemplateId}`,
    );
  }

  createAdTemplate(
    campaignId: string,
    adGroupTemplateId: string,
    adTemplate: Omit<AdTemplate, 'id'>,
  ) {
    return this.httpClient.post<AdTemplate>(
      `api/campaignTemplates/${campaignId}/adGroupsTemplates/${adGroupTemplateId}/adTemplates`,
      adTemplate,
    );
  }

  editAdTemplate(
    campaignId: string,
    adGroupTemplateId: string,
    adTemplate: AdTemplate,
  ) {
    return this.httpClient.put<AdTemplate>(
      `api/campaignTemplates/${campaignId}/adGroupsTemplates/${adGroupTemplateId}/adTemplates/${adTemplate.id}`,
      adTemplate,
    );
  }

  createKeywordTemplate(
    campaignId: string,
    adGroupTemplateId: string,
    keywordTemplate: Omit<KeyWordTemplate, 'id'>,
  ) {
    return this.httpClient.post<KeyWordTemplate>(
      `api/campaignTemplates/${campaignId}/adGroupsTemplates/${adGroupTemplateId}/keywordTemplates`,
      keywordTemplate,
    );
  }

  editKeywordTemplate(
    campaignId: string,
    adGroupTemplateId: string,
    keywordTemplate: KeyWordTemplate,
  ) {
    return this.httpClient.put<KeyWordTemplate>(
      `api/campaignTemplates/${campaignId}/adGroupsTemplates/${adGroupTemplateId}/keywordTemplates/${keywordTemplate.id}`,
      keywordTemplate,
    );
  }

  deleteKeywordTemplate(
    campaignId: string,
    adGroupTemplateId: string,
    keywordTemplateId: string,
  ) {
    return this.httpClient.delete<boolean>(
      `api/campaignTemplates/${campaignId}/adGroupsTemplates/${adGroupTemplateId}/keywordTemplates/${keywordTemplateId}`,
    );
  }

  deleteAdTemplate(
    campaignId: string,
    adGroupTemplateId: string,
    adTemplateId: string,
  ) {
    return this.httpClient.delete<boolean>(
      `api/campaignTemplates/${campaignId}/adGroupsTemplates/${adGroupTemplateId}/adTemplates/${adTemplateId}`,
    );
  }
}
